img {
  /* max-width: 30%; */
  height: auto;
}

a {
  color: #000000;
}
.bold {
  font-weight: bold;
}

.card-img-top {
  width: 100%;
  height: 10vw;
  min-height: 150px;
  object-fit: cover;
}

.card-text{
  font-size: 1rem;
}


.card .card-body h4 {
  font-weight: 600;
  font-size: 1.2rem;
}


.card-text-desc{
  font-size: 1rem;
}

.SocialMediaShareButton {
  cursor: pointer;
  display: inline-block;
}

.SocialMediaShareButton:hover:not(:active) {
  opacity: 0.75;
}