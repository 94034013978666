body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 1500px) {
  .container{
      max-width: 1300px;
  }
}

h1{
  font-weight: 900;
}
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
img {
  /* max-width: 30%; */
  height: auto;
}

a {
  color: #000000;
}
.bold {
  font-weight: bold;
}

.card-img-top {
  width: 100%;
  height: 10vw;
  min-height: 150px;
  object-fit: cover;
}

.card-text{
  font-size: 1rem;
}


.card .card-body h4 {
  font-weight: 600;
  font-size: 1.2rem;
}


.card-text-desc{
  font-size: 1rem;
}

.SocialMediaShareButton {
  cursor: pointer;
  display: inline-block;
}

.SocialMediaShareButton:hover:not(:active) {
  opacity: 0.75;
}
